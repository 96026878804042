const tutonics =
  `` +
  `==================================

       made by tutonics.com

==================================
`;

window.console.log(tutonics);
