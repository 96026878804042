import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Messages = () => (
  <ToastContainer
    position="top-right"
    autoClose={7000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

ReactDOM.render(<Messages />, document.getElementById("messages-root"));

window.MyArdenDoor["messages"].forEach((message) => toast(message));
